// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyA9M7UTzHAkvD-85oWjzTJBrC-Utx7vwlU",
  authDomain: "artifai.firebaseapp.com",
  databaseURL: "https://artifai.firebaseio.com",
  projectId: "artifai",
  storageBucket: "artifai.appspot.com",
  messagingSenderId: "74056311705",
  appId: "1:74056311705:web:d364c80f65146a9d0c6908",
  measurementId: "G-KQ0LP2RSP8"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics.isSupported().then((isSupported) => {
  isSupported && firebase.analytics();
});
firebase.firestore();


export default firebase;
