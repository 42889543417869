import React, { useEffect } from "react";
import TripleImageViewList from "./components/TripleImageViewList";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";

export default () => {
  const firestore = useFirestore();
  const uid = useSelector((state) => state.firebase.auth.uid);
  const recentUserArtifaications = useSelector(state => state.firestore.ordered.processedUserArtifaications);
  const [userArtifaications, setUserArtifaications] = React.useState([]);

  const loadMorePosts = () => {
    (async () => {
      let userArtifaicationsQuery = firestore
        .collection(`users/${uid}/artifaications`)
        .where("percentProcessed", "==", 100)
        .orderBy("created", "desc");

      userArtifaications.length !== 0 &&
        (userArtifaicationsQuery = userArtifaicationsQuery.startAfter(
          userArtifaications.slice(-1)[0]["created"]
        ));

      userArtifaicationsQuery = userArtifaicationsQuery.limit(15);

      let userArtifaicationsSnapshot = [];
      const querySnapshot = await userArtifaicationsQuery.get();

      querySnapshot.forEach((doc) => {
        userArtifaicationsSnapshot.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      setUserArtifaications([
        ...userArtifaications,
        ...userArtifaicationsSnapshot,
      ]);
    })();
  };

  // updates vs additions
  // handle both cases. add additions and afterwards handle updates.
  const topMergePosts = () => {
    (async () => {

      const currentIds = userArtifaications.map(artifaicationObject => artifaicationObject.id);
      let tmpUserArtifaications = userArtifaications.slice();

      let userArtifaicationsQuery = firestore
          .collection(`users/${uid}/artifaications`)
          .where("percentProcessed", "==", 100)
          .orderBy("created", "desc");

      userArtifaicationsQuery = userArtifaicationsQuery.limit(10);

      let userArtifaicationsSnapshot = [];
      const querySnapshot = await userArtifaicationsQuery.get();

      querySnapshot.forEach((doc) => {
        if (!currentIds.includes(doc.id)) {
          userArtifaicationsSnapshot.push({
            id: doc.id,
            ...doc.data(),
          });
        } else {
          tmpUserArtifaications[currentIds.indexOf(doc.id)] = {
            id: doc.id,
            ...doc.data()
          };
        }
      });

      setUserArtifaications([
        ...userArtifaicationsSnapshot,
        ...tmpUserArtifaications,
      ]);
    })();
  };
  // loadMorePosts();
  // useEffect(() => {
  //   loadMorePosts();
  // }, []);

  useEffect(() => {
    topMergePosts();
    console.log("Recent user artifaications updated");
  }, [recentUserArtifaications]);

  useFirestoreConnect([
    {
      collection: `users/${uid}/artifaications`,
      limit: 10,
      orderBy: ['created', 'desc'],
      storeAs: "processedUserArtifaications",
    },
  ]);

  return (
    <>
      <Typography variant="h2" style={{ marginTop: "150px", fontSize: "3rem" }} align="center">
        Your Artifaications
      </Typography>
      <TripleImageViewList
        feed={userArtifaications}
        view="user"
        loadMorePosts={loadMorePosts}
      />
    </>
  );
};
