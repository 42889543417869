import React from "react";
import {useSelector} from "react-redux";
import {Route,Redirect} from "react-router-dom";
import {isLoaded,isEmpty} from "react-redux-firebase";

export default function PrivateRoute({ component:Component, ...rest }) {
    const auth = useSelector(state => state.firebase.auth)
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoaded(auth) && !isEmpty(auth) ? (
                   <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}