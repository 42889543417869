// const fastStyleData = [
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fstarry_night_google.jpg?alt=media&token=23ac1ff4-c01c-426e-a90b-ab6a50fa2e5a',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fpsycwolf.jpg?alt=media&token=6e59ec8c-4cef-4006-8628-9722ef2bf4a3',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fjerry.jpg?alt=media&token=ff2eac1f-f129-4893-8be6-19ba213b25b9',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fegypt.jpg?alt=media&token=99539797-45ba-46dd-bb6b-c7df0ef6a070',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fcolorbeet.jpg?alt=media&token=242cd993-36cc-47b6-859d-7f97371a8468',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fboystyle.jpg?alt=media&token=5e04f8b6-97ff-464b-b2b5-fbe24aab127a',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fgear.jpg?alt=media&token=298cc45c-7712-4b6e-a0b9-5190e9b54811',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fprettycity.jpg?alt=media&token=9be8b198-76af-4324-8b1c-eb6bdaf1a6eb',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fyarn.jpg?alt=media&token=8c0b66ff-b79e-4df5-ad46-28a8f9ffb5d5'
// ];


// {
//   styleImageUrl:
//   "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fjapan.jpg?alt=media&token=95d056b3-247b-4199-bdaf-aeef80b9991a",
//   modelName: "japan"
// },


// const fastStyleData = [
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fstarry_night_google.jpg?alt=media&token=23ac1ff4-c01c-426e-a90b-ab6a50fa2e5a',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fpsycwolf.jpg?alt=media&token=6e59ec8c-4cef-4006-8628-9722ef2bf4a3',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fjerry.jpg?alt=media&token=ff2eac1f-f129-4893-8be6-19ba213b25b9',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fegypt.jpg?alt=media&token=99539797-45ba-46dd-bb6b-c7df0ef6a070',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fcolorbeet.jpg?alt=media&token=242cd993-36cc-47b6-859d-7f97371a8468',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fboystyle.jpg?alt=media&token=5e04f8b6-97ff-464b-b2b5-fbe24aab127a',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fgear.jpg?alt=media&token=298cc45c-7712-4b6e-a0b9-5190e9b54811',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fprettycity.jpg?alt=media&token=9be8b198-76af-4324-8b1c-eb6bdaf1a6eb',
//   'https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fyarn.jpg?alt=media&token=8c0b66ff-b79e-4df5-ad46-28a8f9ffb5d5'
// ];


// {
//   styleImageUrl:
//   "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fjapan.jpg?alt=media&token=95d056b3-247b-4199-bdaf-aeef80b9991a",
//   modelName: "japan"
// },


const popularStyleData = [
  {
    name: "starryNight",
    styleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fstarry_night.jpg?alt=media&token=1bc33604-fd86-4f22-88d4-d136cf860d01",
    smallStyleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fstarry_night_600x600.jpg?alt=media&token=424dfbbd-19d3-49eb-864a-c781c5a964aa"
  },
  {
    name: "prettyCity",
    styleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fprettycity.jpg?alt=media&token=2709bb9e-4f9d-4207-a01b-62cdbea555fb",
    smallStyleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fprettycity_600x600.jpg?alt=media&token=faa95a6a-8614-480d-9ded-8a69a846afe9"
  },
  {
    name: "mosaic",
    styleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fmosaic.jpg?alt=media&token=fa6adf52-b690-4a83-b383-c3156ef3e267",
    smallStyleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fmosaic_600x600.jpg?alt=media&token=e0750d57-b64c-4ae7-825d-793a06c5ee7a"
  },
  {
    name: "abstract",
    styleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fabstract.jpg?alt=media&token=b5500cd8-f53d-40c0-8936-cbfe096fb52e",
    smallStyleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fabstract_600x600.jpg?alt=media&token=c88fb74b-fde7-4231-966d-2ab271c339e2"
  },
  {
    name: "graffiti",
    styleImageUrl:
"https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fgraffiti.jpg?alt=media&token=db60b66b-e2d9-468c-8902-b70045f0a094",
    smallStyleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fgraffiti.jpg?alt=media&token=db60b66b-e2d9-468c-8902-b70045f0a094"
  },
  {
    name: "impasto",
    styleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fimpasto.jpg?alt=media&token=16615552-d54f-4872-bd50-60af3328f18f",
    smallStyleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fimpasto_600x600.jpg?alt=media&token=dc407c24-59f9-4da3-80e7-85463f7c1b24"
  },
  {
    name: "geometric",
    styleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Flion.png?alt=media&token=16953c54-e34f-4687-9f76-5b597ce51fed",
    smallStyleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Flion_600x600.png?alt=media&token=6ce5223b-4b1f-4311-b78b-8c3e24e26334"
  },
  {
    name: "colorbeet",
    styleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fcolorbeet.jpg?alt=media&token=04daba71-cad7-43dc-8b85-a877c34dea00",
    smallStyleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fcolorbeet_600x600.jpg?alt=media&token=97969e8c-76dc-4f46-afad-380ba32c85d2"
  },
  {
    name: "yarn",
    styleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fyarn.jpg?alt=media&token=5dd543a6-c477-4c7e-b287-3ef5940fc6cb",
    smallStyleImageUrl: "https://firebasestorage.googleapis.com/v0/b/artifai.appspot.com/o/popularPresetImages%2Fyarn_600x600.jpg?alt=media&token=f6b93679-abf9-47ea-b407-06a6ee28cc70"
  }
];



export default popularStyleData;
