import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import gallery from './gallery';
import user from './user';
import shareModal from './shareModal';

const rootReducer = combineReducers({
  user: user,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  gallery: gallery,
  shareModal: shareModal
});

export default rootReducer;
