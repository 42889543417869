import React  from "react";
import { CSSTransition } from "react-transition-group";
import Grid from "@material-ui/core/Grid";

let VerticalLoadingBarPiece = (props) => (
  <Grid
    container
    alignItems="center"
    direction="column"
    style={{ height: "100%", position: "relative" }}
  >
    <CSSTransition
      in={props.before}
      addEndListener={(node) => {
        node.addEventListener("transitionend", () => false, false);
      }}
      classNames="progress-bars"
    >
      <div
        style={{
          opacity: !props.before ? "0" : "1",
          width: !props.before ? "15%" : "16%",
          height: !props.before ? "0%" : "1%",
          backgroundColor: "#007bff",
        }}
      ></div>
    </CSSTransition>

    <div
      style={{
        width: "15%",
        height: !props.before ? "49%" : "48%",
        backgroundColor: "gray",
      }}
    ></div>

    <CSSTransition
      in={props.before && props.after}
      addEndListener={(node) => {
        node.addEventListener("transitionend", () => false, false);
      }}
      classNames="progress-bars"
    >
      <div
        style={{
          width: "15%",
          height: "1%",
          backgroundColor: "#007bff",
        }}
      ></div>
    </CSSTransition>

    <div
      style={{
        top: "50%",
        marginTop: "-25px",
        backgroundColor: "#007bff",
        borderRadius: "50%",
        height: "50px",
        width: "50px",
        position: "absolute",
      }}
    >
      <CSSTransition
        in={props.after}
        addEndListener={(node) => {
          node.addEventListener("transitionend", () => false, false);
        }}
        classNames="progress-bubble"
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "30px",
            width: "30px",
            position: "absolute",
            top: "50%",
            left: "50%",
            margin: "-15px 0px 0px -15px",
          }}
        ></div>
      </CSSTransition>
    </div>

    <div
      style={{
        width: "15%",
        height: "50%",
        backgroundColor: "gray",
      }}
    ></div>
  </Grid>
);

export default VerticalLoadingBarPiece;
