import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";

import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";

import ImageGridSelector from "./ImageGridSelector";

import popularPresetData from "./tileData";

import {
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { uuid } from "uuidv4";

import keepTrying from "./AddResizedImageDownloadUrl";
import _ from "lodash";

const fastStyleImages = popularPresetData.map(
  (object) => object.smallStyleImageUrl
);

const StyleImageModal = (props) => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const storageRef = firebase.storage().ref();

  const [state, setState] = React.useState({
    userStyleData: [],
    yourStyles: [],
    popularPresets: new Array(fastStyleImages.length).fill(false),
    tab: "1",
  });

  const { uid, firestoreStyles } = useSelector((store) => {
    return {
      uid: store.firebase.auth.uid || null,
      firestoreStyles: store.firestore.ordered.userStyles || [],
    };
  });

  useEffect(() => {
    if (
      firestoreStyles.length !== 0 &&
      firestoreStyles[0].smallStyleImageUrl !== "undefined" &&
      firestoreStyles.length >= state.userStyleData.length - 1 &&
      ((firestoreStyles.length > 0 && state.userStyleData.length === 0) ||
        firestoreStyles[0]?.smallStyleImageUrl !==
          state.userStyleData[0]?.smallStyleImageUrl)
    ) {
      setState({
        ...state,
        yourStyles: new Array(firestoreStyles.length).fill(false),
        userStyleData: firestoreStyles,
      });
    }

    // setState({
    //   if ((! _.isEqual(tmpUserStyles, state.userStyleData)) && tmpUserStyles.length >= state.userStyleData.length) {
    //   }
    // })
  }, [firestoreStyles]);

  useFirestoreConnect([
    {
      collection: `users/${uid}/userStyles`,
      limit: 15,
      orderBy: ["created", "desc"],
      storeAs: "userStyles",
    },
  ]);

  const handleTabChange = (event, newState) => {
    setState({
      ...state,
      tab: newState,
    });
  };

  const selectStyleImage = (name) => {
    return (styleId) => {
      if (state[name][styleId] || state[name]?.filter(Boolean)?.length < 3) {
        let newState = Object.assign({}, state);
        let selected = state[name].map((style, index) =>
          styleId === index ? !style : style
        );

        newState[name] = selected;
        setState(newState);
      }
    };
  };

  const uploadStyleToProfile = (e) => {
    // let styleImages = Object.assign({}, state.styleImages);

    const styleFile = e.target.files[0];

    const styleImagePath = uuid();

    const styleRef = storageRef.child(uid + "/" + styleImagePath);
    const smallStyleRef = storageRef.child(
      uid + "/" + styleImagePath + "_600x600"
    );

    // upload to firebase
    styleRef
      .put(styleFile)
      .then((snapshot) => snapshot.ref.getDownloadURL())
      .then((styleImageUrl) => {
        const styleId = uuid();

        let styleDoc = firestore
          .collection("users")
          .doc(uid)
          .collection("userStyles")
          .doc(styleId);

        styleDoc.set({
          styleImageUrl,
          created: firestore.Timestamp.now(),
        });

        keepTrying(10, smallStyleRef).then((url) => {
          styleDoc.update({
            smallStyleImageUrl: url,
          });
        });
      });

    let tmpStyleImages = state.userStyleData.slice();

    tmpStyleImages.unshift({ smallStyleImageUrl: null });

    setState({
      ...state,
      userStyleData: tmpStyleImages,
      yourStyles: state.yourStyles.slice().unshift(false),
    });

    // let reader = new FileReader();
    // reader.onloadend = () => {
    // };
    // reader.readAsDataURL(styleFile);
  };

  const prepareStyleImages = () => {
    let selectedStyles = [];
    let selectedFastStyles = [];

    console.log("Preparing styles");
    console.log(state.popularPresets);

    state.yourStyles.forEach((style, index) => {
      if (style) selectedStyles.push(state.userStyleData[index]);
    });

    state.popularPresets.forEach((style, index) => {
      if (style) selectedFastStyles.push(popularPresetData[index]);
    });

    console.log(selectedFastStyles);
    const styleImages = {
      selectedStyles,
      selectedFastStyles,
    };

    console.log(styleImages);
    props.selectStyleImages(selectedStyles, selectedFastStyles);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <TabContext value={state.tab}>
        <AppBar position="static" color="inherit">
          <TabList
            onChange={handleTabChange}
            aria-label="simple tabs example"
            variant="fullWidth"
            textColor="primary"
          >
            <Tab label="Popular Presets" value="1" />
            <Tab label="Your Styles" value="2" />
          </TabList>
        </AppBar>
        <TabPanel value="1">
          <ImageGridSelector
            uploadStyleToProfile={uploadStyleToProfile}
            selectStyleImage={(index) =>
              selectStyleImage("popularPresets")(index)
            }
            tileData={fastStyleImages}
            selected={state.popularPresets}
            uploadImage={false}
          />
        </TabPanel>
        <TabPanel value="2">
          <ImageGridSelector
            uploadStyleToProfile={uploadStyleToProfile}
            selectStyleImage={(index) => selectStyleImage("yourStyles")(index)}
            tileData={state.userStyleData.map(
              (object) => object.smallStyleImageUrl
            )}
            selected={state.yourStyles}
            uploadImage={true}
          />
        </TabPanel>
      </TabContext>
      <DialogActions>
        <Button color="primary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={prepareStyleImages}>
          Select Styles
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StyleImageModal;
