import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import copy from "copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { hideShareModal } from "../redux/actions.js";

import LinkIcon from "@material-ui/icons/Link";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
// import * as compose from "lodash.flowright";

import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

const ShareModal = () => {
  // const shareUrl = "https://artif.ai/artifaication/66b9b150-d5b2-4b7e-8b58-400aceeffb19";
  // const shareUrl = window.location.href;
  const dispatch = useDispatch();
  const { showShareModal, artifaicationId } = useSelector((state) => state.shareModal);
  const title = "Check out this cool artifaication I made!";
  const shareUrl = `https://artif.ai/artifaication/${artifaicationId}`;

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const copyArtifaicationLinkToClipboard = () => {
    console.log("Attempting to copy");
    copy(shareUrl);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  return (
    <div>
      <Dialog
        open={showShareModal}
        onClose={() => dispatch(hideShareModal())}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Share Your Artifaication!
        </DialogTitle>
        <DialogContent dividers={true}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FacebookShareButton url={shareUrl} quote={title}>
              <FacebookIcon size={50} round />
            </FacebookShareButton>
            {/* <FacebookMessengerShareButton url={shareUrl} quote={title}> */}
            {/*   <FacebookMessengerIcon size={50} round /> */}
            {/* </FacebookMessengerShareButton> */}
            <TwitterShareButton url={shareUrl} quote={title}>
              <TwitterIcon size={50} round />
            </TwitterShareButton>
            <RedditShareButton url={shareUrl} quote={title}>
              <RedditIcon size={50} round />
            </RedditShareButton>
            <IconButton
              onClick={copyArtifaicationLinkToClipboard}
              style={{ height: "50px", width: "50px" }}
            >
              <LinkIcon size={50} round />
            </IconButton>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch(hideShareModal())} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Artifaication url copied to clipboard"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default ShareModal;
