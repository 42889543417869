import React from "react";
import IndexView from "../views/IndexView";

const IndexViewController = (props) => {
  const tryItOut = () => {
    props.history.push("/upload");
  };

  return (
    <IndexView animate={props.isFirstLoad <= 0}>
      <tryitout1 onClick={tryItOut} />
      <tryitout2 onClick={tryItOut} />
      <tryitout3 onClick={tryItOut} />
      <tryitout4 onClick={tryItOut} />
      <tryitout5 onClick={tryItOut} />
    </IndexView>
  );
};

export default IndexViewController;
