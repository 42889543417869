import React from "react";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { Alert, AlertTitle } from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import { useFirestoreConnect } from "react-redux-firebase";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
import Popover from "@material-ui/core/Popover";

import LoadingSnackBarRow from "./LoadingSnackBarRow";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: "auto",
  },
  snackbar: {
    padding: "0 5px",
  },
  root: {
    padding: "6px 0 6px 16px",
  },
  message: {
    // width: "175px",
    // right: "30px",
    width: "200px",
    right: "20px",
    position: "relative",
  },
}));

const LoadingSnackBar = (props) => {
  const classes = useStyles();
  const { artifaications, uid } = useSelector((state) => ({
    artifaications: state.firestore.ordered.userArtifaications || [],
    uid: state.firebase.auth.uid || null,
  }));

  const [snackbarOpen, setOpenSnackbar] = React.useState(true);

  const handleSnackbarToggle = () => {
    setOpenSnackbar(!snackbarOpen);
  };

  useFirestoreConnect([
    {
      collection: `users/${uid}/artifaications`,
      limit: 10,
      orderBy: ["created", "desc"],
      storeAs: "userArtifaications",
    },
  ]);

  // useFirestoreConnect([
  //   {
  //     collection: `artifaications`,
  //     where: [["uid", "==", uid]],
  //     limit: 10,
  //     orderBy: ['created', 'desc'],
  //     storeAs: "userArtifaications",
  //   },
  // ]);

  // const recentArtifaications = artifaications;
  const recentArtifaications = artifaications.filter(
    (artifaication) =>
      (Date.now() - artifaication.created.toMillis()) / (1000 * 60) < 20
  );

  return (
    <div>
      <div style={{display: snackbarOpen ? "flex" : "none" }}>
        <Snackbar
          TransitionComponent={Grow}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={recentArtifaications.length !== 0}
          message="Processing your image"
        >
          <Alert
            elevation={6}
            severity="info"
            classes={{ message: classes.message, root: classes.root }}
          >
            <AlertTitle>
              <div style={{ textAlign: "center" }}>
                Processing Art
                <IconButton
                  onClick={handleSnackbarToggle}
                  style={{
                    padding: "0 0 0 5px",
                    position: "relative",
                    left: "25px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </AlertTitle>
            <Divider variant="middle" />
            <>
              {recentArtifaications.map((artifaication, index) => (
                <LoadingSnackBarRow
                  artifaication={artifaication}
                  key={artifaication.id}
                />
              ))}
            </>
          </Alert>
        </Snackbar>
      </div>
    <div style={{display: !snackbarOpen ? "flex" : "none" }}>
        <Snackbar
          TransitionComponent={Grow}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={recentArtifaications.length !== 0}
        >
          <Alert
            elevation={6}
            severity="info"
            onClick={handleSnackbarToggle}
            classes={{ icon: classes.icon }}
          ></Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default LoadingSnackBar;
