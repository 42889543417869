import React, { useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { NavLink as RouterLink } from "react-router-dom";

import TripleImageCard from "./components/TripleImageCard";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

import { setGallery } from "./redux/actions.js";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Slider from "@material-ui/core/Slider";

import CircularProgress from "@material-ui/core/CircularProgress";

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import Alert from "@material-ui/lab/Alert";

const Artifaication = (props) => {
  const firestore = useFirestore();
  const { artifaicationId } = useParams();
  const uid = useSelector((state) => state.firebase.auth.uid);
  const [artifaicationData, setArtifaicationData] = React.useState({});
  // 0 represents no error
  // 1 represents fetch error
  const [fetchError, setFetchError] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const dispatch = useDispatch();

  const toggleSettings = () => {
    setOpenSettings(!openSettings);
  };

  useEffect(() => {
    let fetchArtifaicationData = async () => {
      let artifaicationQuery = firestore
        .collectionGroup(`artifaications`)
        .where("artifaicationId", "==", artifaicationId)
        .where("accessLevel", "==", "public")
        .limit(1);

      artifaicationQuery
        .get()
        .then((querySnapshot) => {
          console.log("Got artifaicationQuery snapshot");
          let counter = 0;
          querySnapshot.forEach((doc) => {
            console.log({
              id: doc.id,
              ...doc.data(),
            });
            setArtifaicationData({
              id: doc.id,
              ...doc.data(),
            });
            counter++;
            console.log(artifaicationData);
          });
          if (counter === 0 && uid != null) {
            let userArtifaicationQuery = firestore
              .collection(`users/${uid}/artifaications`)
              .where("artifaicationId", "==", artifaicationId)
              .where("percentProcessed", "==", 100)
              .limit(1);

            return userArtifaicationQuery.get();
          } else {
            return Promise.resolve(true);
          }
        })
        .then((value) => {
          if (value !== true) {
            const querySnapshot = value;

            let counter = 0;

            querySnapshot.forEach((doc) => {
              setArtifaicationData({
                id: doc.id,
                ...doc.data(),
              });
              counter++;
            });
            if (counter === 0) {
              return Promise.reject();
            }
          }
          return Promise.resolve();
        })
        .catch((err) => {
          console.log("Artifaication not public or doesn't exist. Trying user");
          console.log(err);
          setFetchError(true);
          document.body.style = "background: #CF0063;";
        });
    };
    if (Object.keys(artifaicationData).length === 0) {
      fetchArtifaicationData();
    }
  }, [artifaicationId, uid]);

  useEffect(() => {
    return () => {
      document.body.style = "background: white;";
    };
  }, []);

  return (
    <div>
      {fetchError === true ? (
        <div style={{ width: "80%", margin: "150px auto 0px auto" }}>
          <Typography variant="h2" style={{ width: "60%", color: "white" }}>
            Could not find artifaication. Either it isn't set to "public" or it
            doesn't exist.
          </Typography>
          <Typography
            variant="h5"
            style={{ marginTop: "40px", width: "60%", color: "white" }}
          >
            Click{" "}
            <Link
              color="inherit"
              style={{ fontWeight: "bold" }}
              component={RouterLink}
              to="/upload"
            >
              here
            </Link>{" "}
            to go to the create page and make an awesome artifaication!
          </Typography>
        </div>
      ) : Object.keys(artifaicationData).length !== 0 ? (
        <div>
          <Typography
            variant="h4"
            style={{ width: "70%", margin: "150px auto 0px auto" }}
          >
            Artifaication
          </Typography>
          <TripleImageCard
            tileData={artifaicationData}
            index={artifaicationData.id}
            showViewButton={false}
            cardHeight={400}
          />
          <Card
            variant="outlined"
            style={{
              overflow: "visible",
              width: "60%",
              margin: "50px auto 100px auto",
              padding: "0px 15px",
            }}
          >
            <CardHeader
              title="Settings"
              action={
                <IconButton aria-label="settings collapse">
                  {openSettings ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              }
              onClick={toggleSettings}
            />
            <Collapse in={openSettings} timeout="auto" unmountOnExit>
              <CardContent>
                <Alert
                  severity="info"
                  style={{
                    marginBottom: "36px",
                  }}
                >
                  These are the settings that were used when this artifaication
                  was created.
                </Alert>
                <FormLabel component="legend">Enhance</FormLabel>
                <Slider
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  value={artifaicationData.enhanceWeight}
                  step={10}
                  min={0}
                  max={100}
                  style={{
                    width: "100%",
                  }}
                />
                <FormLabel component="legend">Style Weight</FormLabel>
                <Slider
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  value={artifaicationData.styleWeight}
                  step={5}
                  min={0}
                  max={100}
                  style={{
                    width: "100%",
                  }}
                />
                <FormLabel component="legend">Resolution</FormLabel>
                <RadioGroup
                  aria-label="resolution"
                  name="resolution"
                  value={artifaicationData.resolution}
                  row
                >
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="HD (2 MP)"
                  />
                  <FormControlLabel
                    value="8"
                    control={<Radio />}
                    label="4K (8 MP)"
                  />
                  <FormControlLabel
                    value="20"
                    control={<Radio />}
                    label="6K (20 MP)"
                  />
                </RadioGroup>
                <FormLabel component="legend">
                  Preserve Original Colors
                </FormLabel>
                <RadioGroup
                  aria-label="preserveOriginalColors"
                  name="preserveOriginalColors"
                  value={artifaicationData.preserveOriginalColors}
                  row
                >
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
                <FormLabel component="legend">Color Assistance</FormLabel>
                <RadioGroup
                  aria-label="colorTransfer"
                  name="colorTransfer"
                  value={artifaicationData.colorTransfer}
                  row
                >
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
                <FormLabel component="legend">Access</FormLabel>
                <RadioGroup
                  aria-label="access"
                  name="accessLevel"
                  value={artifaicationData.accessLevel}
                  row
                >
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="Private"
                  />
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="Public"
                  />
                </RadioGroup>
              </CardContent>
            </Collapse>
          </Card>
        </div>
      ) : (
        <CircularProgress
          style={{
            top: "50%",
            left: "50%",
            display: "block",
            position: "absolute",
          }}
        />
      )}
    </div>
  );
};

export default Artifaication;
