import { SET_GALLERY, TOGGLE_GALLERY } from "../actionTypes";

const initialState = {
  srcGallery: [],
  showGallery: false
};

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_GALLERY: {
    return {
      srcGallery: action.payload,
      showGallery: true
    };
  }
  case TOGGLE_GALLERY: {
    return {
      ...state,
      showGallery: ! state.showGallery
    };
  }
  default:
    return state;
  }
}
