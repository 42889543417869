/*
 * action types
 */

import { SET_GALLERY, TOGGLE_GALLERY } from "./actionTypes";
import { SET_USER_TOKEN } from "./actionTypes";
import { SHOW_SHARE_MODAL, HIDE_SHARE_MODAL } from "./actionTypes";

/*
 * other constants
 */

/*
 * action creators
 */

export const setGallery = (galleryArray) => ({
  type: SET_GALLERY,
  payload: galleryArray,
});

export const toggleGallery = () => ({
  type: TOGGLE_GALLERY,
});

//user
export function setUserToken(token) {
  return { type: SET_USER_TOKEN, token };
}

export const showShareModal = (artifaicationId) => ({
  type: SHOW_SHARE_MODAL,
  artifaicationId
});

export const hideShareModal = () => ({
  type: HIDE_SHARE_MODAL
});

