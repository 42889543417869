import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withFirebase, withFirestore } from "react-redux-firebase";
import Grid from "@material-ui/core/Grid";
// import * as compose from "lodash.flowright";
import { connect } from "react-redux";
import { compose } from "redux";

import EmailIcon from "@material-ui/icons/Email";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
const google_icon = require("../img/utility/google.svg");
const facebook_icon = require("../img/utility/facebook.svg");
const actionCodeSettings = {
  url: "https://artif.ai/upload",
  // url: "http://localhost:3000/upload",
  handleCodeInApp: true,
  iOS: {
    bundleId: "com.example.ios",
  },
  android: {
    packageName: "com.example.android",
    installApp: true,
    minimumVersion: "12",
  },
};

const styles = {
  buttonLabel: {
    justifyContent: "start",
    display: "flex",
  },
};

class SignInModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      signInWithEmail: false,
      emailSignInComplete: false,
    };
  }

  resetStateAndClose = () => {
    this.props.handleClose();
    setTimeout(() => {
      this.setState({
        signInWithEmail: false,
        emailSignInComplete: false,
      });
    }, 500);
  };

  toggleEmailSignin = () => {
    this.setState({
      signInWithEmail: !this.state.signInWithEmail,
    });
  };

  emailSignin = (event) => {
    event.preventDefault();
    this.props.firebase
      .auth()
      .sendSignInLinkToEmail(this.state.email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", this.state.email);
        this.setState({
          emailSignInComplete: "true",
        });
      })
      .catch((error) => {
        this.setState({
          emailError: error.message,
        });
      });
  };

  signInWithFacebook = () => {
    const facebookProvider = new this.props.firebase.auth.FacebookAuthProvider();
    this.props.firebase
      .auth()
      .currentUser.linkWithPopup(facebookProvider)
      .then((result) => {
        const credential = result.credential;
        const user = result.user;
        console.log(`Account successfully linked: ${user}`);
        this.props.dispatch({
          type: "@@reactReduxFirebase/AUTH_LINK_SUCCESS",
          payload: result.user,
        });
        this.props.firebase.login({
          credential,
        });
      })
      .catch((error) => {
        console.log("Error upgrading anonymous account", error);
        this.props.firebase.login({
          credential: error.credential,
        });
      })
      .finally(() => {
        this.resetStateAndClose();
      });
  };

  signInWithGoogle = () => {
    const googleProvider = new this.props.firebase.auth.GoogleAuthProvider();

    this.props.firebase
      .auth()
      .currentUser.linkWithPopup(googleProvider)
      .then((result) => {
        const credential = result.credential;
        const user = result.user;
        console.log(`Account successfully linked: ${user}`);

        this.props.dispatch({
          type: "@@reactReduxFirebase/AUTH_LINK_SUCCESS",
          payload: result.user,
        });
        this.props.firebase.login({
          credential,
        });
      })
      .catch((error) => {
        console.log("Error upgrading anonymous account", error);
        this.props.firebase.login({
          credential: error.credential,
        });
      })
      .finally(() => {
        this.resetStateAndClose();
      });
  };

  render() {
    const { classes } = this.props;

    if (this.state.emailSignInComplete) {
      return (
        <Dialog
          open={this.props.open}
          onClose={this.resetStateAndClose}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Sign-in email sent</DialogTitle>
          <DialogContent dividers={true} style={{}}>
            <CheckCircleOutlineIcon
              style={{
                fontSize: 80,
                color: "green",
                display: "block",
                margin: "auto",
              }}
            />
            <DialogContentText>
              A sign-in email with additional instructions was sent to{" "}
              {this.state.email}. Check your email to complete sign-in.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.resetStateAndClose}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else if (this.state.signInWithEmail) {
      return (
        <Dialog
          open={this.props.open}
          onClose={this.resetStateAndClose}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Sign in with email</DialogTitle>
          <form onSubmit={this.emailSignin}>
            <DialogContent dividers={true} style={{}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                    error={this.state.emailError !== ""}
                    helperText={this.state.emailError}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleEmailSignin} color="primary">
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Sign in
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      );
    } else {
      return (
        <Dialog
          open={this.props.open}
          onClose={this.resetStateAndClose}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth
        >
          {this.props.titletext}
          {/* <DialogTitle id="form-dialog-title">Sign In</DialogTitle> */}
          <DialogContent dividers={true}>
            {this.props.contenttext}
            {/* <DialogContentText> */}
            {/*   Sign in to save your creations for later and get alerts when they */}
            {/*   finish processing! */}
            {/* </DialogContentText> */}
            <Grid container spacing={2} style={{ margin: "25px 0 25px 0" }}>
              <Grid item xs={12} sm={12}>
                <Grid container justify="center" spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Button
                      classes={{ label: classes.buttonLabel }}
                      variant="contained"
                      startIcon={
                        <img
                          style={{
                            height: "30px",
                            margin: "0 20px 0 10px",
                          }}
                          src={google_icon}
                          alt=""
                        />
                      }
                      size="small"
                      onClick={this.signInWithGoogle}
                      style={{
                        height: 50,
                        backgroundColor: "#FFFFFF",
                        width: "80%",
                        display: "block",
                        margin: "auto",
                      }}
                    >
                      Sign in with Google
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Button
                      classes={{ label: classes.buttonLabel }}
                      variant="contained"
                      size="small"
                      startIcon={
                        <img
                          style={{
                            height: "30px",
                            margin: "0 20px 0 10px",
                          }}
                          src={facebook_icon}
                          alt=""
                        />
                      }
                      onClick={this.signInWithFacebook}
                      style={{
                        height: 50,
                        backgroundColor: "#3A5997",
                        width: "80%",
                        display: "block",
                        margin: "auto",
                        color: "white",
                      }}
                      /* disabled={renderProps.disabled} */
                    >
                      Sign in with Facebook
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      classes={{ label: classes.buttonLabel }}
                      variant="contained"
                      size="small"
                      startIcon={
                        <EmailIcon
                          style={{ fontSize: 30, margin: "0 20px 0 10px" }}
                        />
                      }
                      onClick={() => this.toggleEmailSignin()}
                      style={{
                        height: 50,
                        backgroundColor: "#DB4437",
                        width: "80%",
                        display: "block",
                        margin: "auto",
                        color: "white",
                      }}
                      /* disabled={renderProps.disabled} */
                    >
                      Sign in with email
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      classes={{ label: classes.buttonLabel }}
                      variant="contained"
                      size="small"
                      startIcon={
                        <PersonOutlineIcon
                          style={{ fontSize: 30, margin: "0 20px 0 10px" }}
                        />
                      }
                      onClick={this.resetStateAndClose}
                      style={{
                        height: 50,
                        backgroundColor: "#F4B400",
                        width: "80%",
                        display: "block",
                        margin: "auto",
                      }}
                      /* disabled={renderProps.disabled} */
                    >
                      Continue as guest
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid item xs={12} sm={12}> */}
              {/*   <TextField */}
              {/*     autoFocus */}
              {/*     margin="dense" */}
              {/*     id="email" */}
              {/*     label="Email Address" */}
              {/*     type="email" */}
              {/*     fullWidth */}
              {/*     value={this.state.email} */}
              {/*     onChange={(e) => { */}
              {/*       this.setState({ email: e.target.value }); */}
              {/*     }} */}
              {/*     error={this.state.emailError !== ""} */}
              {/*     helperText={this.state.emailError} */}
              {/*   /> */}
              {/* </Grid> */}
            </Grid>
          </DialogContent>
          {/* <DialogActions> */}
          {/*   <Button onClick={this.resetStateAndClose} color="primary"> */}
          {/*     Cancel */}
          {/*   </Button> */}
          {/*   <Button onClick={this.emailSignin} color="primary"> */}
          {/*     Sign in with email */}
          {/*   </Button> */}
          {/* </DialogActions> */}
        </Dialog>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

export default compose(
  withFirebase,
  withFirestore,
  withStyles(styles),
  connect(mapStateToProps)
)(SignInModal);
