import React, { useState, useEffect, useRef } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import { useHistory } from "react-router-dom";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const LoadingSnackBarRow = (props) => {
  const { artifaication } = props;
  const history = useHistory();
  const [progressDisplay, setProgressDisplay] = useState(
    artifaication.percentProcessed
  );

  useInterval(() => {
    if (progressDisplay < artifaication.percentProcessed) {
      setProgressDisplay(progressDisplay + 1);
    }
  }, 2000);

  return (
    <Grid
      container
      style={{
        alignItems: "center",
        marginTop: "5px",
        height: "50px",
      }}
      spacing={1}
    >
      <Grid item xs={4} style={{ height: "50px" }}>
        {artifaication.percentProcessed === 100 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
          >
            <CardActionArea onClick={() => history.push("/profile")}>
              <Typography variant="caption" component="div" color="textPrimary">
                Artifaied! Click Me!
              </Typography>
            </CardActionArea>
          </Box>
        ) : artifaication.percentProcessed === 0 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
          >
            <LinearProgress style={{ width: "100%", top: "10px" }} />
            <Box position="absolute">
              <Typography variant="caption" component="div" color="textPrimary">
                Queued
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
          >
            <CircularProgress />
            <Box position="absolute" style={{ marginTop: "2px" }}>
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
              >{`${Math.round(progressDisplay)}%`}</Typography>
            </Box>
          </Box>
        )}
      </Grid>
      <Grid item xs={4} style={{ height: "50px" }}>
        <img
          alt=""
          src={artifaication.smallContentImageUrl}
          style={{
            borderRadius: "5px",
            filter: "brightness(75%)",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        ></img>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          height: "50px",
        }}
      >
        <img
          alt=""
          src={artifaication.smallStyleImageUrl}
          style={{
            borderRadius: "5px",
            filter: "brightness(75%)",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        ></img>
      </Grid>
    </Grid>
  );
};

export default LoadingSnackBarRow;
