import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { isEmpty, isLoaded, withFirebase } from "react-redux-firebase";
import CircularProgress from "@material-ui/core/CircularProgress";

function PrivateComponent(props) {
  if (!isLoaded(props.auth)) { // loading logged in state
    return <CircularProgress color="secondary" size={20} />;
  } else if (!isEmpty(props.auth) && !props.auth.isAnonymous) { //logged in with non anonymous account
      return props.loggedin(props);
  } else {// not logged in
      return props.loggedout;
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});
export default compose(
  withFirebase,
  connect(mapStateToProps)
)(PrivateComponent);
