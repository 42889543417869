import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { setGallery } from "../redux/actions.js";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
    height: "100%",
    // border: "black solid 2px",
    margin: 0
  },
  tile: {
    borderRadius: 0,
    // boxShadow: "rgba(0, 0, 0, 0.5) 5px 5px 10px 0px"
  }
}));


const TripleImageView = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <GridList cellHeight={props.cardHeight / 2 - 5} className={classes.gridList} cols={3} spacing={0}>
        <GridListTile
          classes={{ tile: classes.tile }}
          key={1}
          rows={2}
          cols={2}
          onClick={() => dispatch(setGallery([props.artifaiImageUrl, props.contentImageUrl, props.styleImageUrl]))}
        >
          <img src={props.smallArtifaiImageUrl} alt="" />
        </GridListTile>

        <GridListTile
          key={2}
          rows={2}
          cols={1}
        >
          <GridList cellHeight={props.cardHeight / 2 - 5} cols={1} width="100%" height="100%" spacing={0}>
            <GridListTile
              classes={{ tile: classes.tile}}
              key={2.5}
              rows={1}
              cols={1}
              onClick={() => dispatch(setGallery([props.contentImageUrl, props.styleImageUrl, props.artifaiImageUrl]))}
            >
              <img src={props.smallContentImageUrl} style={{ objectFit: "cover" }} alt="" />
            </GridListTile>
            <GridListTile
              classes={{ tile: classes.tile}}
              key={3}
              rows={1}
              cols={1}
              onClick={() => dispatch(setGallery([props.styleImageUrl, props.artifaiImageUrl, props.contentImageUrl]))}
            >
              <img src={props.smallStyleImageUrl} alt="" />
            </GridListTile>
          </GridList>
        </GridListTile>
      </GridList>
    </>
  );
};

export default TripleImageView;
