import { SET_USER_TOKEN } from "../actionTypes";

const INITIAL_STATE = {
  token: null,
};

function user(user = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USER_TOKEN: {
      return {
        ...user,
        token: action.token,
      };
    }
    default:
      return user;
  }
}

export default user;
