import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import Grid from "@material-ui/core/Grid";
import GridListTile from "@material-ui/core/GridListTile";
import { withRouter } from "react-router";
import PhotoSizeSelectLargeIcon from "@material-ui/icons/PhotoSizeSelectLarge";

import "./index.css";

import VerticalLoadingBarPiece from "./components/VerticalLoadingBarPiece";

import { compose } from "redux";
import { withFirebase, withFirestore, isEmpty } from "react-redux-firebase";
import { connect } from "react-redux";
import { uuid } from "uuidv4";
import AdvancedSettingsModal from "./components/AdvancedSettingsModal";
import StyleImageModal from "./components/StyleImageModal";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import Hidden from "@material-ui/core/Hidden";

import keepTrying from "./components/AddResizedImageDownloadUrl";

import axios from "axios";
// axios.defaults.baseURL = "https://artif.ai/";

const styles = {
  disabled: {
    backgroundColor: "#809AE2 !important",
  },
};

class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentImage: "",
      contentImageUrl: "",
      styleImage: "",
      styleImageUrl: "",
      selected: [],
      numSelected: 0,
      startAnimation: false,
      email: "",
      emailError: "", //is this the best way to store error?
      enhanceWeight: 100,
      styleWeight: 50,
      accessLevel: "private",
      preserveOriginalColors: "no",
      colorTransfer: "no",
      resolution: "20",
      openSettingsModal: false,
      openStyleImagesModal: false,
      selectedStyles: [],
      selectedFastStyles: [],
    };
    for (let i = 0; i < 6; i++) {
      this.state.selected.push(false);
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ startAnimation: true }), 0);
    window.scrollTo(0, 0);
    setTimeout(() => {
      alert(
        "Unfortunately due to server expenses, artifaication creation is no longer available to the general public. For special requests please email g.cole.killan@gmai.com"
      );
    }, 500);
  }

  saveSettings = (settings) => {
    this.setState(settings);
    this.setOpenSettingsModal(false);
  };

  setOpenSettingsModal = (open) => {
    this.setState({
      openSettingsModal: open,
    });
  };

  selectStyleImages = (selectedStyles, selectedFastStyles) => {
    console.log(selectedStyles);
    console.log(selectedFastStyles);

    this.setState({
      selectedStyles: selectedStyles,
      selectedFastStyles: selectedFastStyles,
    });

    this.setOpenStyleImagesModal(false);

    // console.log(this.state.selectedFastStyles);
  };

  setOpenStyleImagesModal = (open) => {
    this.setState({
      openStyleImagesModal: open,
    });
  };

  artifaication = async () => {
    console.log("Artifaication activate");
    const content_file = this.state.contentImage;
    // const style_file = this.state.styleImage;

    const storageRef = this.props.firebase.storage().ref();

    const idToken = await this.props.firebase
      .auth()
      .currentUser.getIdToken(true);

    const contentImagePath = uuid();
    // const styleImagePath = uuid();
    // const artifaiImagePath = uuid();

    const contentRef = storageRef.child(
      this.props.auth.uid + "/" + contentImagePath
    );

    const smallContentRef = storageRef.child(
      this.props.auth.uid + "/" + contentImagePath + "_600x600"
    );

    // const styleRef = storageRef.child(
    //   this.props.auth.uid + "/" + styleImagePath
    // );

    console.log(contentRef.fullPath);
    // console.log(styleRef.fullPath);

    const accessLevel = this.state.accessLevel;

    contentRef
      .put(content_file)
      .then((snapshot) => {
        console.log("Content upload success");
        return snapshot.ref.getDownloadURL();
      })
      .then((contentImageUrl) => {
        this.state.selectedStyles.forEach((styleImageObject, index) => {
          const artifaicationId = uuid();
          const { styleImageUrl, smallStyleImageUrl } = styleImageObject;

          this.props.firestore
            .collection("users")
            .doc(this.props.auth.uid)
            .collection("artifaications")
            .doc(artifaicationId)
            .set({
              artifaicationId,
              contentImageUrl,
              styleImageUrl,
              smallStyleImageUrl,
              percentProcessed: 0,
              accessLevel: accessLevel,
              algorithmType: "novel",
              styleWeight: this.state.styleWeight,
              enhanceWeight: this.state.enhanceWeight,
              resolution: this.state.resolution,
              preserveOriginalColors: this.state.preserveOriginalColors,
              colorTransfer: this.state.colorTransfer,
              created: this.props.firestore.Timestamp.now(),
            })
            .then(() => {
              console.log(`Artifaication to Firestore success. `);

              keepTrying(10, smallContentRef).then((url) => {
                console.log("Small Content Ref Update.");
                this.props.firestore
                  .collection("users")
                  .doc(this.props.auth.uid)
                  .collection("artifaications")
                  .doc(artifaicationId)
                  .update({
                    smallContentImageUrl: url,
                  });
              });

              axios
                .post(`/dispatchArtifaicationRequest`, {
                  artifaicationId,
                  idToken,
                })
                .then(function (response) {
                  console.log(response);
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch((err) => {
              console.error("Error writing artifaication document: ", err);
            });
        });

        this.state.selectedFastStyles.forEach((styleImageObject, index) => {
          const artifaicationId = uuid();
          const { styleImageUrl, smallStyleImageUrl } = styleImageObject;

          this.props.firestore
            .collection("users")
            .doc(this.props.auth.uid)
            .collection("artifaications")
            .doc(artifaicationId)
            .set({
              artifaicationId,
              contentImageUrl,
              styleImageUrl,
              smallStyleImageUrl,
              percentProcessed: 0,
              accessLevel: accessLevel,
              algorithmType: "novel",
              styleWeight: this.state.styleWeight,
              enhanceWeight: this.state.enhanceWeight,
              resolution: this.state.resolution,
              preserveOriginalColors: this.state.preserveOriginalColors,
              colorTransfer: this.state.colorTransfer,
              created: this.props.firestore.Timestamp.now(),
            })
            .then(() => {
              console.log("Artifaication to Firestore success");

              keepTrying(10, smallContentRef).then((url) => {
                console.log("Small Content Ref Update.");
                this.props.firestore
                  .collection("users")
                  .doc(this.props.auth.uid)
                  .collection("artifaications")
                  .doc(artifaicationId)
                  .update({
                    smallContentImageUrl: url,
                  });
              });

              axios
                .post(`/dispatchArtifaicationRequest`, {
                  artifaicationId,
                  idToken,
                })
                .then(function (response) {
                  console.log(response);
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch((err) => {
              console.error("Error writing artifaication document: ", err);
            });
        });

        // this.state.selectedFastStyles.forEach((object, index) => {

        //   const styleImageUrl = object.src;
        //   const modelName = object.modelName;
        //   const artifaicationId = uuid();

        //   this.props.firestore
        //     .collection("users")
        //     .doc(this.props.auth.uid)
        //     .collection("artifaications")
        //     .doc(artifaicationId)
        //     .set({
        //       // contentImagePath,
        //       contentImageUrl,
        //       // styleImagePath,
        //       styleImageUrl,
        //       modelName,
        //       // artifaiImagePath,
        //       percentProcessed: 10,
        //       accessLevel: accessLevel,
        //       algorithmType: "fast",
        //       created: this.props.firestore.Timestamp.now(),
        //     })
        //     .then(() => {
        //       console.log("Fast Style Artifaication to Firestore success");

        //       axios.post(`/applyFastStyle`, {
        //         artifaicationId,
        //         idToken
        //       })
        //         .then(function (response) {
        //           console.log(response);
        //         })
        //         .catch(function (error) {
        //           console.log(error);
        //         });
        //     })
        //     .catch((err) => {
        //       console.error("Error writing artifaication document: ", err);
        //     });
        // });
      });

    this.props.history.push("/explore");

    // four options
    // first artifaication and signed in: congratulate on first artifaication
    // first artifaication not signed in: congratulate on first artifaication and prompt sign in
    // multiple artifaications not signed in: prompt sign in
    // multiple artifaications and signed in: do nothing

    // too much work. For now just show the page if they are anonymous

    // if user's first creation or they are anonymous
    console.log(this.props.auth.isAnonymous);
    if (this.props.auth.isAnonymous) {
      this.props.activatePostArtifaiModal();
    }
  };

  uploadContent = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        contentImage: file,
        contentImageUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  uploadStyle = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        styleImage: file,
        styleImageUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  selectStyle = (styleId) => {
    if (!this.state.selected[styleId]) {
      if (this.state.numSelected < 3)
        this.setState({
          numSelected: this.state.numSelected + 1,
        });
      else return;
    } else {
      this.setState({
        numSelected: this.state.numSelected - 1,
      });
    }
    this.setState({
      selected: this.state.selected.map((style, index) =>
        styleId === index ? !style : style
      ),
    });
  };

  formReady = () =>
    this.state.contentImage !== "" &&
    (this.state.selectedStyles.length !== 0 ||
      this.state.selectedFastStyles.length !== 0) &&
    (!isEmpty(this.props.auth) ||
      (this.state.email !== "" && this.state.emailError === ""));

  render() {
    return (
      <>
        <Container>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.artifaication();
            }}
          >
            <Grid container style={{ padding: "130px 0" }}>
              {/* first row */}
              <Hidden smDown>
                <Grid
                  item
                  xs={false}
                  sm={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography gutterBottom variant="h3">
                    Content
                  </Typography>
                  <Typography variant="h5">upload content image</Typography>
                </Grid>

                <Grid item xs={false} sm={1}>
                  <VerticalLoadingBarPiece
                    before={this.state.startAnimation}
                    after={this.state.contentImage !== ""}
                  />
                </Grid>
              </Hidden>

              <Grid item xs={12} sm={7}>
                <Card
                  variant="outlined"
                  style={{
                    width: "90%",
                    margin: "auto",
                    border: "dashed grey 2px",
                    height: "350px",
                  }}
                >
                  <CardActionArea
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      padding: "20px",
                    }}
                    /* onClick={() => this.setOpenStyleImagesModal(true)} */
                    onClick={() => {}}
                  >
                    {this.state.contentImageUrl !== "" ? (
                      <>
                        <label
                          htmlFor="content-image-upload"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <GridList
                            cellHeight={310}
                            spacing={16}
                            /* className={classes.gridList} */
                            cols={1}
                            style={{
                              height: "100% !important",
                            }}
                          >
                            <GridListTile
                              key={this.state.contentImageUrl}
                              cols={1}
                              rows={1}
                            >
                              <img
                                style={{
                                  objectFit: "contain",
                                  height: "100%",
                                  width: "100%",
                                }}
                                src={this.state.contentImageUrl}
                                alt=""
                              />
                            </GridListTile>
                          </GridList>
                        </label>
                        <input
                          id="content-image-upload"
                          type="file"
                          style={{ display: "none" }}
                          accept="image/x-png,image/jpg,image/jpeg"
                          onChange={this.uploadContent}
                        />
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="content-image-upload"
                          style={{
                            cursor: "pointer",
                            /* background: "#bdbdbd", */
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <Typography gutterBottom variant="h5" component="h2">
                            Upload Content Image
                          </Typography>
                          <PhotoSizeSelectLargeIcon fontSize="large" />
                        </label>

                        <input
                          id="content-image-upload"
                          type="file"
                          style={{ display: "none" }}
                          accept="image/x-png,image/jpg,image/jpeg"
                          onChange={this.uploadContent}
                        />
                      </>
                    )}
                  </CardActionArea>
                </Card>
              </Grid>

              {/* second row */}
              <Hidden smDown>
                <Grid
                  item
                  xs={false}
                  sm={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography gutterBottom variant="h3">
                    Style
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    choose up to 3 style images to apply
                  </Typography>
                </Grid>

                <Grid item xs={false} sm={1}>
                  <VerticalLoadingBarPiece
                    before={this.state.contentImage !== ""}
                    after={
                      this.state.selectedStyles.length !== 0 ||
                      this.state.selectedFastStyles.length !== 0
                    }
                  />
                </Grid>
              </Hidden>

              <Grid item xs={12} sm={7} style={{ margin: "50px auto" }}>
                <Card
                  variant="outlined"
                  style={{
                    width: "90%",
                    margin: "auto",
                    border: "dashed grey 2px",
                    height: "350px",
                  }}
                >
                  <CardActionArea
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      padding: "20px",
                    }}
                    // onClick={() => this.setOpenStyleImagesModal(true)}
                    onClick={() => {}}
                  >
                    {this.state.selectedStyles.length !== 0 ||
                    this.state.selectedFastStyles.length !== 0 ? (
                      <GridList
                        cellHeight={310}
                        spacing={16}
                        /* className={classes.gridList} */
                        cols={
                          this.state.selectedStyles.length +
                          this.state.selectedFastStyles.length
                        }
                        style={{
                          height: "100% !important",
                        }}
                      >
                        {this.state.selectedFastStyles
                          .concat(this.state.selectedStyles)
                          .map((object) => object.smallStyleImageUrl)
                          .map((style, index) => (
                            <GridListTile key={style} cols={1} rows={1}>
                              <img
                                src={style}
                                alt=""
                                /* style={{ */
                                /*   objectFit: "contain", */
                                /*   height: "100%", */
                                /*   width: "100%" */
                                /* }} */
                              />
                            </GridListTile>
                          ))}
                      </GridList>
                    ) : (
                      <CardContent
                        style={{
                          cursor: "pointer",
                          /* background: "#bdbdbd", */
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <Typography gutterBottom variant="h5" component="h2">
                          Pick Your Styles
                        </Typography>
                        <PhotoSizeSelectLargeIcon fontSize="large" />
                      </CardContent>
                    )}
                  </CardActionArea>
                </Card>

                {/* <Gallery */}
                {/*   upload={this.uploadStyle} */}
                {/*   styleImageUrl={this.state.styleImageUrl} */}
                {/*   selected={this.state.selected} */}
                {/*   selectStyle={this.selectStyle} */}
                {/* /> */}
              </Grid>

              {/* final row */}
              <Grid
                item
                container
                xs={12}
                sm={12}
                style={{
                  justifyContents: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    margin: "auto",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!this.formReady()}
                    variant="contained"
                    classes={{ disabled: true }}
                    // classes={{ disabled: this.props.classes.disabled }}
                    style={{
                      width: "90%",
                      height: "50px",
                      margin: "auto",
                    }}
                  >
                    Artifai!
                  </Button>
                  <IconButton
                    color="primary"
                    onClick={() => {}}
                    // onClick={() => this.setOpenSettingsModal(true)}
                  >
                    {/* style={{ position: "absolute", right: "0%" }} */}
                    <SettingsIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </form>
        </Container>

        {/* Completely unrendered and rerendered based on openSettingsModal in order to reset internal state */}
        {this.state.openSettingsModal && (
          <AdvancedSettingsModal
            open={this.state.openSettingsModal}
            enhanceWeight={this.state.enhanceWeight}
            styleWeight={this.state.styleWeight}
            accessLevel={this.state.accessLevel}
            preserveOriginalColors={this.state.preserveOriginalColors}
            colorTransfer={this.state.colorTransfer}
            resolution={this.state.resolution}
            saveSettings={this.saveSettings}
            handleClose={() => this.setOpenSettingsModal(false)}
          />
        )}
        <StyleImageModal
          open={this.state.openStyleImagesModal}
          handleClose={() => this.setOpenStyleImagesModal(false)}
          selectStyleImages={this.selectStyleImages}
          selectedStyles={this.state.selectedStyles}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});
export default compose(
  withStyles(styles),
  withFirestore,
  withFirebase,
  withRouter,
  connect(mapStateToProps)
)(UploadForm);
