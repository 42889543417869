import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    margin: "40px auto",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  media: {
    width: "100%",
    height: "250px",
    padding: "16px 0px",
  },
  content: {
    height: "calc(100% - 16px - 24px)",
  },
  statusBad: {
    color: "#ff0033",
  },
  statusGood: {
    color: "#4BB543",
  },
  info: {
    padding: theme.spacing(2),
  },
  infoAddress: {
    lineHeight: "1.2",
  },
}));

export default () => {
  const classes = useStyles();

  const { bearerToken } = useSelector((state) => ({
    bearerToken: state.user.token || null,
  }));

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
  const [selectedInfoIndex, setSelectedInfoIndex] = React.useState(null);

  const openInfo = (event) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const closeInfo = () => {
    setInfoAnchorEl(null);
    setSelectedInfoIndex(null);
  };

  const Info = () => {
    let address_info = orders[selectedInfoIndex].address_to;
    return (
      <div className={classes.info}>
        <Typography variant="h6" style={{ lineHeight: "1.2" }}>
          {`${address_info.first_name} ${address_info.last_name}`}
        </Typography>
        <Typography className={classes.infoAddress}>
          {address_info.address1}
        </Typography>
        <Typography className={classes.infoAddress}>
          {`${address_info.city},${address_info.region} ${address_info.zip}`}
        </Typography>
        <Typography className={classes.infoAddress}>
          {address_info.country}
        </Typography>
        <Typography variant="subtitle1">{address_info.email}</Typography>
        <Typography variant="subtitle2" className={classes.infoAddress}>
          {address_info.phone}
        </Typography>
        <br />
        <Typography>{`Order Id: ${orders[selectedInfoIndex].id}`}</Typography>
      </div>
    );
  };

  useEffect(() => {
    fetch(
      "https://us-central1-artifai.cloudfunctions.net/printifyApi/user/orders",
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setOrders(data.reverse());
        setLoading(false);
      });
  }, [bearerToken]);

  const goodStatuses = [
    "pending",
    "on-hold",
    "checking-quality",
    "quality-approved",
    "ready-for-production",
    "sending-to-production",
    "in-production",
    "fulfilled",
    "callback-received",
  ];
  // badStatuses = on-hold????, quality-declined, cancelled, partially-fulfilled, payment-not-received, or has-issues.

  return (
    <>
      <Typography variant="h2" style={{ marginTop: "150px", fontSize: "3rem" }} align="center">
        Your Orders
      </Typography>
      {loading ? (
        <Box
          display="flex"
          width="100%"
          height={160}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        orders.map((order, index) => (
          <Card className={classes.root} elevation={8} key={index}>
            <CardHeader
              action={
                <IconButton
                  aria-label="more info"
                  onClick={(e) => {
                    setSelectedInfoIndex(index);
                    openInfo(e);
                  }}
                >
                  <InfoIcon />
                </IconButton>
              }
              title={"Status - " + order.status}
              subheader={new Date(order.created_at).toLocaleString()}
              classes={
                goodStatuses.includes(order.status)
                  ? { title: classes.statusGood }
                  : { title: classes.statusBad }
              }
              id={"info" + index}
            />
            <Grid container justify="center">
              <Grid item xs={6}>
                <CardActionArea
                  className={classes.media}
                  href={"/product/" + order.metadata.shop_order_label}
                >
                  <CardMedia
                    image={order.mockups[0].src}
                    title={order.title}
                    style={{
                      height: "100%",
                      backgroundSize: "auto 100%",
                      backgroundPosition: "80% 50%",
                    }}
                  />
                </CardActionArea>
              </Grid>
              <Grid item xs={6}>
                <CardContent className={classes.content}>
                  <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="stretch"
                    style={{ height: "100%" }}
                  >
                    <Grid item>
                      <Typography gutterBottom variant="h5" component="h2">
                        {order.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" component="h2">
                        {"Quantity: " + order.line_items[0].quantity}
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {"Total: " +
                          (order.price / 100).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                      </Typography>
                      {order.sent_to_production_at && (
                        <Typography variant="h6" component="h2">
                          {"Sent to production at: " +
                            new Date(
                              order.sent_to_production_at
                            ).toLocaleString()}
                        </Typography>
                      )}
                      {order.fulfilled_at && (
                        <Typography variant="h6" component="h2">
                          {"fulfilled at: " +
                            new Date(order.fulfilled_at).toLocaleString()}
                        </Typography>
                      )}
                      {order.shipments && (
                        <Button
                          size="small"
                          color="primary"
                          href={order.shipments[0].url}
                        >
                          Track Package
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        ))
      )}
      {
        //seems incorrect to not render the entire popover component but no workaround as there needs to be some child prop if always rendered
        selectedInfoIndex !== null && (
          <Popover
            open={Boolean(infoAnchorEl)}
            anchorEl={infoAnchorEl}
            onClose={closeInfo}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Info />
          </Popover>
        )
      }
    </>
  );
};
