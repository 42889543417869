/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
const loadjs = require('loadjs');

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=5f2dda51c2612e23d6a6b31f").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class IndexView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/IndexController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  componentWillMount() {
    loadjs(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=5f2dda51c2612e23d6a6b31f",
      function () {
        loadjs("js/webflow.js", function () {
          document.getElementById("animation-button").click();
        });
      }
    );
  }

  // componentDidMount() {
  //   scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
  //     return loaded.then((script) => {
  //       new Function(`
  //         with (this) {
  //           eval(arguments[0])
  //         }
  //       `).call(window, script)

  //       return loading
  //     })
  //   })
  // }

  render() {
    const proxies = Controller !== IndexView ? transformProxies(this.props.children) : {
      'tryitout1': [],
      'tryitout2': [],
      'tryitout3': [],
      'tryitout4': [],
      'tryitout5': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/artifa-mobile.webflow.css);


          .mobile-artifai-fill-available {
          height: -moz-available !important;          /* WebKit-based browsers will ignore this. */
          height: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
          height: fill-available !important;
          }
        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div data-w-id="4e969de5-dbb7-4e4a-e205-2edd565b379b" className="af-class-section af-class-center">
              <div className="af-class-div-block-13"><a id="animation-button" data-w-id="561a4276-a5ae-dba9-1b13-38e4a6b1d0ef" href="#" className="af-class-button-2 w-button">Button Text</a>
                <div data-w-id="6b220cb1-4b9c-7fb0-75b9-53ff4a6c35a2" style={{WebkitTransform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-hero-content">
                  <h1 className="af-class-heading-3">Art has never been so <span className="af-class-text-span-3">powerful</span>.</h1>{map(proxies['tryitout1'], props => <a href="#" {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Try it Free</React.Fragment>}</a>)}</div><img src="images/boatprintalpha.png" style={{WebkitTransform: 'translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} data-w-id="77e69727-c455-8e07-0990-833a661c94ea" sizes="(max-width: 479px) 80vw, (max-width: 991px) 70vw, 100vw" srcSet="images/boatprintalpha-p-500.png 500w, images/boatprintalpha-p-800.png 800w, images/boatprintalpha.png 1024w" alt="" className="af-class-hero-image" /><img src="images/arrow.svg" alt="" className="af-class-arrow" /></div>
            </div>
            <div id="sec01" data-w-id="0d5e03bb-42f9-b2ff-4d6d-501a3e1c2216" className="af-class-the-height-400vh-section">
              <div className="af-class-the-sticky-div">
                <h1 data-w-id="0d5e03bb-42f9-b2ff-4d6d-501a3e1c2218" style={{WebkitTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-the-sticky-div-title">It's simple.<br />‍<span className="af-class-text-span-2">just</span></h1>
                <div className="af-class-night-sky-fixed-background" />
                <div className="af-class-the-overflow-hidden-mask">
                  <div className="af-class-the-width-400vh-scrollable-div">
                    <div className="af-class-the-content"><img src="images/content.png" width={399} data-w-id="69274cb6-6943-c493-c837-9c98e01e8492" sizes="(max-width: 479px) 60vw, (max-width: 767px) 32vw, (max-width: 991px) 25vw, 30vw" srcSet="images/content-p-500.png 500w, images/content-p-800.png 800w, images/content.png 1024w" alt="" className="af-class-image-7" /><img src="images/style.png" width={399} data-w-id="0650c8c6-55a6-23fb-6c30-31a256b07f6a" sizes="(max-width: 479px) 60vw, (max-width: 767px) 32vw, (max-width: 991px) 25vw, 30vw" srcSet="images/style-p-500.png 500w, images/style-p-800.png 800w, images/style.png 1024w" alt="" className="af-class-image-8" /><img src="images/beatlealpha.png" width={399} data-w-id="bec73b22-618f-c2ab-94b5-9631ca54e777" sizes="(max-width: 479px) 60vw, (max-width: 767px) 32vw, (max-width: 991px) 25vw, 30vw" srcSet="images/beatlealpha-p-500.png 500w, images/beatlealpha-p-800.png 800w, images/beatlealpha.png 1024w" alt="" className="af-class-image-9" />
                      <ul role="list" className="af-class-list-2">
                        <li className="af-class-list-item">
                          <div data-w-id="a8d4d99a-035e-e9a0-66b6-9de6535fe2fc" className="af-class-text-block-5">Upload an image</div>
                        </li>
                        <li className="af-class-list-item-2">
                          <div className="af-class-text-block-4">Choose a Style</div>
                        </li>
                        <li className="af-class-list-item-3">
                          <div className="af-class-text-block-4-copy-copy">Thats it!</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-w-id="e62f15ca-9425-e618-1c22-980b2bb4a90b" className="af-class-parallax">
              <div className="af-class-container af-class-para-big w-container"><img src="images/bee.png" sizes="(max-width: 479px) 70vw, (max-width: 991px) 36vw, 35vw" srcSet="images/bee-p-500.png 500w, images/bee-p-800.png 800w, images/bee-p-1080.png 1080w, images/bee.png 1500w" alt="" className="af-class-image-14" /><img src="images/japanfamily.png" sizes="(max-width: 479px) 60vw, (max-width: 767px) 21vw, (max-width: 991px) 23vw, 26vw" srcSet="images/japanfamily-p-500.png 500w, images/japanfamily-p-800.png 800w, images/japanfamily.png 999w" alt="" className="af-class-image-15" /><img src="images/mcgill.png" sizes="(max-width: 479px) 80vw, (max-width: 767px) 44vw, (max-width: 991px) 46vw, 40vw" srcSet="images/mcgill-p-500.png 500w, images/mcgill-p-800.png 800w, images/mcgill-p-1080.png 1080w, images/mcgill.png 1500w" alt="" className="af-class-image-19" /><img src="images/backfamily.png" sizes="(max-width: 479px) 40vw, (max-width: 991px) 44vw, 25vw" srcSet="images/backfamily-p-500.png 500w, images/backfamily-p-800.png 800w, images/backfamily-p-1080.png 1080w, images/backfamily.png 1500w" alt="" className="af-class-image-21" /><img src="images/lion.png" sizes="(max-width: 479px) 70vw, 35vw" srcSet="images/lion-p-500.png 500w, images/lion-p-800.png 800w, images/lion-p-1080.png 1080w, images/lion.png 1500w" alt="" className="af-class-image-22" /></div>
            </div>
            <div className="af-class-section af-class-up af-class-perks">
              <div id="features" className="af-class-feature-wrap">
                <div data-w-id="4593c4b7-56e8-060a-be57-648b0e08fc65" style={{WebkitTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-feature-content">
                  <div className="af-class-title af-class-perk-title">PERFECT&nbsp;FOR</div>
                  <h2>A&nbsp;Gift</h2>
                  <p className="af-class-perk-para">Use Artifai to surprise someone with the perfect personalized present!</p>{map(proxies['tryitout2'], props => <a href="#" {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Try it Free</React.Fragment>}</a>)}</div>
                <div className="af-class-feature-image"><img src="images/stephen.jpg" width={341} sizes="(max-width: 479px) 90vw, (max-width: 991px) 40vw, 28vw" srcSet="images/stephen-p-500.jpeg 500w, images/stephen-p-800.jpeg 800w, images/stephen-p-1080.jpeg 1080w, images/stephen.jpg 1200w" alt="" className="af-class-image-2" /></div>
              </div>
              <div className="af-class-feature-wrap af-class-reverse">
                <div data-w-id="eeafd7e3-5d2c-e624-ecad-d6e1537ff1ac" style={{WebkitTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-feature-content">
                  <div className="af-class-title af-class-perk-title">Create</div>
                  <h2>A Friends or Family Portrait</h2>
                  <p className="af-class-perk-para">Hang a tasteful portrait to decorate your home!</p>{map(proxies['tryitout3'], props => <a href="#" {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Try it Free</React.Fragment>}</a>)}</div>
                <div className="af-class-feature-image"><img src="images/friends.jpg" width={386} sizes="(max-width: 479px) 90vw, (max-width: 991px) 40vw, 32vw" srcSet="images/friends-p-1080.jpeg 1080w, images/friends.jpg 1500w" alt="" className="af-class-image-2" /></div>
              </div>
              <div className="af-class-feature-wrap">
                <div data-w-id="125f4b90-0782-b2a7-2d05-7b6833d136c4" style={{WebkitTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-feature-content">
                  <div className="af-class-title af-class-perk-title">BUILD</div>
                  <h2>An Artifaication</h2>
                  <p className="af-class-perk-para">Bring your own art and photos to life!</p>{map(proxies['tryitout4'], props => <a href="#" {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Try it Free</React.Fragment>}</a>)}</div>
                <div className="af-class-feature-image"><img src="images/bird.jpg" width={341} sizes="(max-width: 479px) 90vw, (max-width: 991px) 40vw, 28vw" srcSet="images/bird-p-500.jpeg 500w, images/bird-p-1080.jpeg 1080w, images/bird-p-1600.jpeg 1600w, images/bird-p-2000.jpeg 2000w, images/bird-p-2600.jpeg 2600w, images/bird-p-3200.jpeg 3200w, images/bird.jpg 5800w" alt="" className="af-class-image-2" /></div>
              </div>
            </div>
            <div id="download" className="af-class-section af-class-wide af-class-purple">
              <div data-w-id="376e2a1f-ec70-0dab-11ea-b1863101e37b" style={{WebkitTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-wrap">
                <h2>Ready to get started?</h2>{map(proxies['tryitout5'], props => <a href="#" {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Try it Free</React.Fragment>}</a>)}</div>
            </div>
            <div className="af-class-footer">
              <div className="af-class-wrap"><img src="images/logo512.png" width={91} sizes="91px" srcSet="images/logo512-p-500.png 500w, images/logo512.png 512w" alt="" />
                <div className="af-class-footer-list"><a href="mailto:contact@artif.ai" className="af-class-footer-link">Email</a><a href="https://www.instagram.com/artifai.gallery/" className="af-class-footer-link">Instagram</a><a href="https://www.facebook.com/artifai.gallery" className="af-class-footer-link">Facebook</a></div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView

/* eslint-enable */
