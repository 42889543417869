import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import Button from "@material-ui/core/Button";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";

import Slider from "@material-ui/core/Slider";

const RadioButtonsGroup = (props) => {
  const [state, setState] = React.useState({
    enhanceWeight: props.enhanceWeight,
    styleWeight: props.styleWeight,
    accessLevel: props.accessLevel,
    preserveOriginalColors: props.preserveOriginalColors,
    colorTransfer: props.colorTransfer,
    resolution: props.resolution,
  });

  const handleSettingsChange = (name) => (event, value) => {
    let newState = Object.assign({}, state);
    newState[name] = value;

    setState(newState);
  };

  return (
    <Dialog
      open={props.open}
      /* onClose={props.handleClose} */
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Advanced Settings</DialogTitle>
      <DialogContent style={{"overflow": "visible"}}>
        {/* <DialogContentText> */}
        {/* </DialogContentText> */}
        <FormLabel component="legend">Enhance</FormLabel>
        <Slider
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          value={state.enhanceWeight}
          onChange={handleSettingsChange("enhanceWeight")}
          step={10}
          min={0}
          max={100}
          style={{
            width: "100%",
          }}
        />
        <FormLabel component="legend">Style Weight</FormLabel>
        <Slider
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          value={state.styleWeight}
          onChange={handleSettingsChange("styleWeight")}
          step={5}
          min={0}
          max={100}
          style={{
            width: "100%",
          }}
        />
        <FormLabel component="legend">Resolution</FormLabel>
        <RadioGroup
          aria-label="resolution"
          name="resolution"
          value={state.resolution}
          onChange={handleSettingsChange("resolution")}
          row
        >
          <FormControlLabel value="2" control={<Radio />} label="HD (2 MP)" />
          <FormControlLabel value="8" control={<Radio />} label="4K (8 MP)" />
          <FormControlLabel value="20" control={<Radio />} label="6K (20 MP)" />
        </RadioGroup>
        <FormLabel component="legend">Preserve Original Colors</FormLabel>
        <RadioGroup
          aria-label="preserveOriginalColors"
          name="preserveOriginalColors"
          value={state.preserveOriginalColors}
          onChange={handleSettingsChange("preserveOriginalColors")}
          row
        >
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <FormLabel component="legend">Color Assistance</FormLabel>
        <RadioGroup
          aria-label="colorTransfer"
          name="colorTransfer"
          value={state.colorTransfer}
          onChange={handleSettingsChange("colorTransfer")}
          row
        >
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <FormLabel component="legend">Access</FormLabel>
        <RadioGroup
          aria-label="access"
          name="accessLevel"
          value={state.accessLevel}
          onChange={handleSettingsChange("accessLevel")}
          row
        >
          <FormControlLabel
            value="private"
            control={<Radio />}
            label="Private"
          />
          <FormControlLabel value="public" control={<Radio />} label="Public" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => props.saveSettings(state)} color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RadioButtonsGroup;
