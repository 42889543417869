import React from "react";
import { useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Modal from "@material-ui/core/Modal";


import { toggleGallery } from "../redux/actions.js";

const GalleryModal = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = React.useState(0);

  const nextImage = () => {
    setState((state + 1) % 3);
  };

  const previousImage = () => {
    setState((state + 2) % 3);
  };

  const handleClose = () => {
    setState(0);
    dispatch(toggleGallery());
  };

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
      }}
      open={props.open}
      onClose={() => handleClose()}
      /* onClose={props.handleClose} */
      aria-labelledby="form-dialog-title"
    >
      {/* <IconButton color="primary" aria-label="Next" component="span" style={{position: "absolute"}}> */}
      {/*   <ChevronLeftIcon/> */}
      {/* </IconButton> */}
      <>
        <IconButton aria-label="Previous" onClick={() => previousImage()}>
          <ChevronLeftIcon fontSize="large" color="action" />
        </IconButton>

        <img
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "scale-down",
            margin: "auto",
          }}
          src={props.src[state]}
          alt="Fullsize"
        />

        <IconButton aria-label="Next" onClick={() => nextImage()}>
          <ChevronRightIcon fontSize="large" color="action" />
        </IconButton>
      </>
    </Modal>
  );
};

export default GalleryModal;
