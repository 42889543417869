import React from "react";
import TripleImageCard from "./TripleImageCard";
import { Waypoint } from "react-waypoint";

const cardHeight = 400;

const TripleImageViewList = (props) => {
  return (
    <>
      <br />
      {props.feed.map((tileData, index) => (
        <React.Fragment key={tileData.id}>
          <TripleImageCard
            tileData={tileData}
            index={index}
            cardHeight={cardHeight}
            view={props.view}
          />
          {(index === props.feed.length - 5 && (props.feed.length < 100)) && (
            <Waypoint onEnter={() => props.loadMorePosts()} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default TripleImageViewList;
