import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Moment from "react-moment";
import { setGallery } from "../redux/actions.js";
import { showShareModal } from "../redux/actions.js";
import { Link as RouterLink } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useDispatch } from "react-redux";
import TripleImageView from "./TripleImageView";
import { makeStyles } from "@material-ui/core/styles";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    margin: "40px auto",
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
  },
  media: {
    height: 140,
  },
  label: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));

const TripleImageCard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const uid = useSelector((store) => store.firebase.auth.uid);


  const { tileData, index, cardHeight, showViewButton = true } = props;
  console.log(showViewButton);

  const [accessLevel, setAccessLevel] = React.useState(
    tileData.accessLevel === "public"
  );

  const toggleAccessLevel = (event) => {
    const toggledAccessLevel = !accessLevel;

    firestore
      .collection("users")
      .doc(uid)
      .collection("artifaications")
      .doc(tileData.id)
      .update({
        accessLevel: toggledAccessLevel ? "public" : "private",
      });

    setAccessLevel(toggledAccessLevel);
  };

  return (
    <Card className={classes.root} elevation={8} key={index}>
      <CardActionArea>
        <TripleImageView key={index} cardHeight={cardHeight} {...tileData} />
      </CardActionArea>
      <CardActions>
        {/* <Button size="small" color="primary"> */}
        {/*   Share */}
        {/* </Button> */}
        <Button
          size="small"
          color="primary"
          component={RouterLink}
          to={"/product/" + tileData.id}
        >
          Order Canvas
        </Button>
        {showViewButton !== false && (
          <Button
            size="small"
            color="primary"
            component={RouterLink}
            to={"/artifaication/" + tileData.id}
          >
            View
          </Button>
        )}
        <Button
          size="small"
          color="primary"
          onClick={() => dispatch(showShareModal(tileData.id))}
        >
          Share
        </Button>
        {props.view === "user" && (
          <Button size="small" color="primary">
            <FormControlLabel
              classes={{ label: classes.label }}
              control={
                <Switch
                  checked={accessLevel}
                  onChange={toggleAccessLevel}
                  name="checkedB"
                  color="primary"
                  size="small"
                />
              }
              labelPlacement="start"
              label="Published"
            />
          </Button>
        )}
        {/* <Typography variant="p" style={{ marginLeft: "auto" }}> */}
        {/*   Created {dateToString(tileData.created.toDate())} */}
        {/* </Typography> */}
        <Button size="small" color="primary" style={{ marginLeft: "auto" }}>
          <Moment fromNow>{tileData.created.toDate()}</Moment>
        </Button>
      </CardActions>
    </Card>
  );
};

export default TripleImageCard;
