import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import { useParams } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AddIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageGallery from "react-image-gallery";
import ReactImageMagnify from "react-image-magnify";
import { loadStripe } from "@stripe/stripe-js";

const useStyles = makeStyles((theme) => ({
  sizeStyle: {
    marginTop: 5,
  },
  sizeButtonStyle: {
    variant: "contained",
    width: 96,
    textTransform: "none",
    display: "inline-block",
    margin: "0 5px 0 5px",
  },
  sizeButtonSelectedStyle: {
    variant: "contained",
    width: 96,
    textTransform: "none",
    display: "inline-block",
    margin: "0 5px 0 5px",
  },
}));

// testing stripe public key
// const stripePromise = loadStripe(
//   "pk_test_51HGpU7Bx4d8tjRP9L85loLlhFCT5p7k52GWSREbrwyH0QUcfShTZNT7SzTQ7gPnNgOWjbMzX8L0BP6IjHzF2Yr4F00qDZjf1dL"
// );

// production stripe public key
const stripePromise = loadStripe(
  "pk_live_51HGpU7Bx4d8tjRP9pFFzNOPSYmZO6UB9hsaIu8rNf3vNueLn1E03dE2lWERAQPsXrsw1z2wwPoe6UDIbdCMUqDiF00DEqMRSw3"
);

function Product(props) {
  const classes = useStyles();
  const { artifaicationId } = useParams();

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState({});
  const [variant, setVariant] = useState();
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);

  const { bearerToken } = useSelector((state) => ({
    bearerToken: state.user.token || null,
  }));

  const blueprints = {
    // blueprint 50 is for a canvas wrap
    50: {
      title: "Canvas Wrap",
      sizes: { 33721: '10"x8"', 33726: '8"x10"' },
    },
  };
  const currentBlueprint = 50; //eventually this should be changed to a state or a url parameter depending on how we decide to sell different products

  let galleryImages = [];

  useEffect(() => {
    //how to do a post new product
    // fetch(
    //   "http://localhost:5001/artifai/us-central1/printifyApi/user/product",
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${bearerToken}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       // imageUrl: "https://wallpaperaccess.com/full/405435.jpg",
    //       imageUrl: "https://image.shutterstock.com/image-photo/yes-concept-positive-changes-life-260nw-290151644.jpg",
    //       artifaicationId,
    //     }),
    //   }
    // )
    //   .then((response) => {
    //     console.log(response);
    //     return response.json();
    //   })
    //   .then((data) => {
    //     console.log(data);
    //   });

    //fetch("https://us-central1-artifai.cloudfunctions.net/printifyApi/user/products?artifaicationId=" + artifaicationId, {
    if (bearerToken && artifaicationId)
      // fetch(
      // "http://localhost:5001/artifai/us-central1/printifyApi/user/products?artifaicationId=" +
      fetch(
        "https://us-central1-artifai.cloudfunctions.net/printifyApi/user/products?artifaicationId=" +
          artifaicationId,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      )
        .then((response) => {
          console.log(response);
          if (response.ok) return response.json();
          throw new Error(JSON.stringify(response.json()));
        })
        .then((data) => {
          console.log(data);
          let activeVariants = Object.keys(blueprints[currentBlueprint].sizes);
          setProducts(data);
          setVariant(activeVariants[0]);

          //this for loop section eventually can be moved into its own use effect and change based on [product,variant] depending on if the images should change based on selected variant
          /* for(let image of data[currentBlueprint].images){
                         console.log(activeVariants[0])
                         console.log(image);
                         if(image.variant_ids.includes(parseInt(activeVariants[0])))
                             galleryImages.push({
                                 original:image.src,
                                 thumbnail:image.src
                             });
                     }*/
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [bearerToken, artifaicationId]);

  useEffect(() => {
    if (!loading) {
      //this for loop section eventually can be moved into its own use effect and change based on [product,variant] depending on if the images should change based on selected variant
      for (let image of products[currentBlueprint].images) {
        console.log(variant);
        console.log(image);
        if (image.variant_ids.includes(parseInt(variant)))
          galleryImages.push({
            original: image.src,
            thumbnail: image.src,
          });
      }
      for (let avariant of products[currentBlueprint].variants) {
        if (avariant.id == variant) {
          setPrice(avariant.price/100);
          break;
        }
      }
    }
  });

  const handleCount = (name) => {
    if (name === "add") {
      setQuantity(quantity + 1);
    }
    if (name === "remove" && quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  // const handleSize = () => {
  //   return (
  //     <div>
  //       <Grid container>
  //         {Object.keys(blueprints[currentBlueprint].sizes).map(
  //           (variantSize) => (
  //             <Grid item className={classes.sizeStyle} key={variantSize} xs={3}>
  //               <Button
  //                 onClick={() => {
  //                   setVariant(variantSize);
  //                 }}
  //                 className={
  //                   variantSize === variant
  //                     ? classes.sizeButtonSelectedStyle
  //                     : classes.sizeButtonStyle
  //                 }
  //                 variant="outlined"
  //               >
  //                 {blueprints[currentBlueprint].sizes[variantSize]}
  //               </Button>
  //             </Grid>
  //           )
  //         )}
  //       </Grid>
  //     </div>
  //   );
  // };

  const handleSize = () => {
    return (
      <div>
        {Object.keys(blueprints[currentBlueprint].sizes).map((variantSize) => (
          <Button
            onClick={() => {
              setVariant(variantSize);
            }}
            className={
              variantSize === variant
                ? classes.sizeButtonSelectedStyle
                : classes.sizeButtonStyle
            }
            variant="contained"
            color={variantSize === variant ? "primary" : "default"}
          >
            {blueprints[currentBlueprint].sizes[variantSize]}
          </Button>
        ))}
      </div>
    );
  };

  const stripeRedirect = async (e) => {
    // Get Stripe.js instance
    const stripe = await stripePromise;

    const response = await fetch(
      "https://us-central1-artifai.cloudfunctions.net/printifyApi/user/product/" +
        products[currentBlueprint].id +
        "/checkout",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          artifaicationId,
          variantId: variant,
          quantity,
          shippingMethod: 1,
        }),
      }
    );
    const session = await response.json();
    console.log(session);

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  return (
    <Container>
      {loading ? (
        <Box
          display="flex"
          width="100%"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={8} style={{ marginTop: "150px" }}>
          <Grid item xs={12} sm={6}>
            <ImageGallery
              items={galleryImages}
              showPlayButton={false}
              showFullscreenButton={false}
              showNav={false}
              renderItem={(item) => (
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: item.originalAlt,
                      isFluidWidth: true,
                      src: item.original,
                    },
                    largeImage: {
                      src: item.original,
                      width: 1200,
                      height: 1800,
                    },
                    enlargedImagePortalId: "descriptionPortal",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              id="descriptionPortal"
              style={{ position: "absolute", zIndex: "100" }}
            />
            <div style={{ marginTop: "50px" }}>
              <div>
                <h2>{blueprints[currentBlueprint].title}</h2>
              </div>
              <div>
                <strike style={{ fontSize: "20px" }}>
                  {"$" + (price + 10).toFixed(2)}
                </strike>
                <br/>
                <span style={{ fontSize: "30px" }}>
                  {"$" + price.toFixed(2)}
                  <span style={{
                    fontSize: "20px",
                    marginLeft: "5px"
                  }}>
                    &amp; Free Shipping
                  </span>
                </span>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <hr></hr>
            </div>
            <div>
              <Grid container alignItems="center" style={{ fontSize: "20px" }}>
                <Grid item xs={2}>
                  <p>Count:</p>
                </Grid>
                <Grid item xs={10}>
                  <IconButton
                    onClick={() => handleCount("remove")}
                    size="small"
                  >
                    <RemoveIcon color="primary"></RemoveIcon>
                  </IconButton>
                  <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                    {quantity}
                  </span>
                  <IconButton size="small" onClick={() => handleCount("add")}>
                    <AddIcon color="primary"></AddIcon>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container alignItems="center" style={{ fontSize: "20px" }}>
                <Grid item xs={2}>
                  <p>Size:</p>
                </Grid>
                <Grid item xs={10}>
                  {handleSize()}
                </Grid>
              </Grid>
            </div>
            <div style={{ width: "100%" }}>
              <hr></hr>
            </div>
            <div>A perfect addition to any room! Our standard canvas is a finely textured artist-grade cotton substrate which consistently reproduces image details with outstanding clarity and detail.</div>
            <div style={{ marginTop: "20px" }}>
              <ul>
                <li>100% cotton fabric</li>
                <li>High image quality and detail</li>
                <li>Lifelong color guarantee</li>
              </ul>
            </div>
            <Alert severity="info">
              Upon purchase, the Artifai team will reach out about potential
              image positioning adjustments before sending the canvas to
              production. We can also so some image touchups in photoshop :)
            </Alert>
            <Grid
              container
              xs={12}
              alignContent="center"
              style={{ marginTop: "20px" }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: "150px",
                  height: "50px",
                  border: "0px",
                  borderRadius: "6px",
                  marginRight: "10px",
                }}
                onClick={stripeRedirect}
              >
                Buy Now
              </Button>
              {/* <button */}
              {/*   style={{ */}
              {/*     width: "75px", */}
              {/*     height: "50px", */}
              {/*     backgroundColor: "white", */}
              {/*     color: "black", */}
              {/*     outline: "none", */}
              {/*     border: "1px solid rgb(212, 212, 212)", */}
              {/*     borderRadius: "6px", */}
              {/*   }} */}
              {/* > */}
              {/*   <ShoppingCartIcon></ShoppingCartIcon> */}
              {/* </button> */}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default Product;
