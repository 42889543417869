import { SHOW_SHARE_MODAL, HIDE_SHARE_MODAL } from "../actionTypes";

const initialState = {
  showShareModal: false,
  artifaicationId: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
  case SHOW_SHARE_MODAL: {
    return {
      showShareModal: true,
      artifaicationId: action.artifaicationId
    };
  }
  case HIDE_SHARE_MODAL: {
    return {
      showShareModal: false,
      artifaicationId: ""
    };
  }

  default:
    return state;
  }
}
