import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";

import ImageSearchIcon from "@material-ui/icons/ImageSearch";

const cardHeight = 150;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "white",
  },
  media: {
    height: "100%",
  },
  layer: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 255, 0.3)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

export default function ImageGridSelector(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList
        cellHeight={150}
        spacing={4}
        className={classes.gridList}
        cols={3}
        style={{
          alignContent: "flex-start",
        }}
      >
        {props.uploadImage && (
          <GridListTile key={0} cols={1} rows={1}>
            <Card elevation={0}>
              <CardActionArea>
                <label
                  htmlFor="style-image-upload"
                  style={{
                    cursor: "pointer",
                    /* background: "#bdbdbd", */
                    border: "dashed black 1px",
                    width: "99%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "148px",
                    borderRadius: "4px",
                  }}
                >
                  <p>Upload Image</p>
                  <ImageSearchIcon />
                </label>
                <input
                  id="style-image-upload"
                  type="file"
                  style={{ display: "none" }}
                  accept="image/x-png,image/jpg,image/jpeg"
                  onChange={props.uploadStyleToProfile}
                />
              </CardActionArea>
            </Card>
          </GridListTile>
        )}

        {props.tileData.map((styleImageUrl, index) => (
          <GridListTile key={styleImageUrl + index} cols={1} rows={1}>
            {styleImageUrl ? (
              <Card elevation={0} style={{ height: cardHeight }}>
                <CardActionArea
                  style={{ height: "100%" }}
                  onClick={() => props.selectStyleImage(index)}
                >
                  <CardMedia
                    className={classes.media}
                    image={styleImageUrl}
                    style={{
                      height: "100%",
                    }}
                  />
                  <GridListTileBar
                    /* title={tile.title} */
                    titlePosition="top"
                    actionIcon={
                      <StarBorderIcon className={classes.icon} />
                      /* <IconButton aria-label={`star`} className={classes.icon}> */
                      /* </IconButton> */
                    }
                    actionPosition="left"
                    className={classes.titleBar}
                  />
                  <div
                    id="layer"
                    className={props.selected[index] ? classes.layer : null}
                  ></div>
                </CardActionArea>
              </Card>
            ) : (
              <Card elevation={0} style={{ height: cardHeight }}>
                <CardActionArea
                  style={{ height: "100%" }}
                  onClick={() => props.selectStyleImage(index)}
                >
              <div style={{display: "flex", justifyContent: "center"}}>
                  <CircularProgress
                    size={80}
                  />
                  </div>
                </CardActionArea>
              </Card>
            )}
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
