import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import Upload from "./Upload.js";
import Explore from "./Explore.js";
import Product from "./Product.js";
import Artifaication from "./Artifaication";
import Profile from "./Profile.js";
import Orders from "./Orders.js";
import PrivateRoute from "./components/PrivateRoute";
import LoadingSnackBar from "./components/LoadingSnackBar";
import IndexViewController from "./controllers/IndexViewController";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
//import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import BrushIcon from "@material-ui/icons/Brush";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { NavLink as RouterLink } from "react-router-dom";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useDispatch } from "react-redux";
import PrivateComponent from "./components/PrivateComponent";
import SignInModal from "./components/SignInModal";
import ShareModal from "./components/ShareModal";
import GalleryModal from "./components/GalleryModal";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { setUserToken } from "./redux/actions";
import { uuid } from "uuidv4";

// import AppliedRoute from "./components/AppliedRoute";

const drawerWidth = 200;

const WEBFLOW_PAGE_ID = "5f2dda51c2612edbe9a6b321";
const WEBFLOW_SITE_ID = "5f2dda51c2612e23d6a6b31f";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "25px",
    },
  },
  item: {
    display: "none",
    width: "auto",
    padding: "10px 15px",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  button: {
    textTransform: "none",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  closeMenuButton: {
    marginRight: "auto",
    marginLeft: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  mediumAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  largeAvatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginRight: theme.spacing(2),
  },
}));

export default function App(props) {
  const classes = useStyles();
  const { location } = props;
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  // const auth = useSelector((state) => state.firebase.auth);
  const { showGallery, srcGallery } = useSelector((state) => state.gallery);
  const [openSignInModal, setSignInModalOpen] = React.useState(false);
  const [openPostArtifaiModal, setOpenPostArtifaiModal] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  function ListItemLink(props) {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <RouterLink to={to} innerRef={ref} {...itemProps} />
        )),
      [to]
    );

    return (
      <ListItem button component={renderLink} onClick={handleDrawerToggle}>
        {/* {icon ? <ListItemIcon>{icon}</ListItemIcon> : null} */}
        <ListItemText primary={primary} />
      </ListItem>
    );
  }

  useEffect(() => {
    // Sign in anonymously
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        await firebase.auth().signInAnonymously();
      }

      let idToken = await firebase.auth().currentUser.getIdToken();
      dispatch(setUserToken(idToken));
      console.log(firebase.auth().currentUser.uid);

      // the following is to sign in a user who comes from an email link
      // Confirm the link is a sign-in with email link.
      if (
        firebase.auth().isSignInWithEmailLink(window.location.href) &&
        firebase.auth().currentUser.isAnonymous
      ) {
        console.log("Sign in with email link");
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt("Please provide your email for confirmation");
        }

        // Construct the email link credential from the current URL.
        let credential = firebase.auth.EmailAuthProvider.credentialWithLink(
          email,
          window.location.href
        );

        // Link the credential to the current user.
        // .auth() .currentUser
        firebase
          .linkWithCredential(credential)
          .then(function (usercred) {
            // successfully linked
            console.log("Link Success");
            // firebase.login({credential});
          })
          .catch(function (error) {
            // Some error occurred.
            console.log("Linking error");
            firebase.login({ credential });
          });
      }
    });

    const doc = document.getElementsByTagName("html")[0];
    doc.setAttribute("data-wf-page", WEBFLOW_PAGE_ID);
    doc.setAttribute("data-wf-site", WEBFLOW_SITE_ID);
  }, []);

  // const navItems = ["Upload", "Explore", "Profile"];
  const navItems = [
    {
      name: "Create",
      to: "/upload",
    },
    {
      name: "Explore",
      to: "/explore",
    },
    {
      name: "Profile",
      to: "/profile",
    },
  ];

  const drawer = (
    <>
      <List>
        {navItems.map((navObject, index) => (
          <ListItemLink
            button
            key={navObject.name}
            to={navObject.to}
            primary={navObject.name}
          />
        ))}
      </List>
    </>
  );

  return (
    <div className="App">
      <div className={classes.root}>
        <AppBar
          position="absolute"
          style={{ background: "#CF0063" }}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h4"
              className={classes.title}
              style={{ fontFamily: "'Lobster', cursive", flexGrow: 1 }}
            >
              <Link color="inherit" component={RouterLink} to="/">
                Artifai
              </Link>
            </Typography>
            <Typography variant="h6" className={classes.item}>
              <Link color="inherit" component={RouterLink} to="/upload">
                Create
              </Link>
            </Typography>
            <Typography variant="h6" className={classes.item}>
              <Link color="inherit" component={RouterLink} to="/explore">
                Explore
              </Link>
            </Typography>
            <PrivateComponent
              loggedin={(userProps) => (
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Avatar
                    alt={userProps.profile.displayName}
                    src={userProps.profile.avatarUrl}
                    className={classes.mediumAvatar}
                  />
                  {/*<AccountCircle />*/}
                </IconButton>
              )}
              loggedout={
                <Typography
                  variant="h6"
                  className={classes.item}
                  onClick={handleMenu}
                  style={{ cursor: "pointer" }}
                >
                  <Link color="inherit">Profile</Link>
                </Typography>
              }
            />
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              {/* div is here because menu requires that the first component is one that it can forward refs to */}
              {/* https://stackoverflow.com/questions/56307332/how-to-use-custom-functional-components-within-material-ui-menu */}
              <div>
                <PrivateComponent
                  loggedin={(userProps) => (
                    <>
                      <MenuItem onClick={handleClose}>
                        <ListItem component="div">
                          <ListItemAvatar>
                            <Avatar
                              alt={userProps.profile.displayName}
                              src={userProps.profile.avatarUrl}
                              className={classes.largeAvatar}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={userProps.profile.displayName}
                            secondary={userProps.auth.email}
                          />
                        </ListItem>
                      </MenuItem>
                      <Divider variant="middle" />
                    </>
                  )}
                  loggedout={null}
                />
              </div>

              <Link color="inherit" component={RouterLink} to="/profile">
                <MenuItem onClick={handleClose}>
                  <ListItem component="div">
                    <ListItemAvatar>
                      <Avatar>
                        <BrushIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Your Art"
                      secondary="see your artifaications"
                    />
                  </ListItem>
                </MenuItem>
              </Link>
              <Link color="inherit" component={RouterLink} to="/orders">
                <MenuItem onClick={handleClose}>
                  <ListItem component="div">
                    <ListItemAvatar>
                      <Avatar>
                        <ReceiptIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Your Orders"
                      secondary="see your purchase history"
                    />
                  </ListItem>
                </MenuItem>
              </Link>
              <PrivateComponent
                loggedin={(userProps) => (
                  <MenuItem
                    onClick={() => {
                      userProps.firebase.logout();
                      handleClose();
                    }}
                  >
                    <ListItem component="div">
                      <ListItemAvatar>
                        <Avatar>
                          <ExitToAppIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Log Out" />
                    </ListItem>
                  </MenuItem>
                )}
                loggedout={null}
              />
            </Menu>
            <PrivateComponent
              loggedin={() => null}
              loggedout={
                <Button
                  color="inherit"
                  onClick={() => setSignInModalOpen(true)}
                  className={classes.button}
                >
                  <Typography variant="h6">Login</Typography>
                </Button>
              }
            />
          </Toolbar>
        </AppBar>
        <SignInModal
          open={openSignInModal}
          handleClose={() => setSignInModalOpen(false)}
          titletext={<DialogTitle>Sign In</DialogTitle>}
          contenttext={
            <DialogContentText>
              Sign in to save your creations for later and get alerts when they
              finish processing!
            </DialogContentText>
          }
        />
        <SignInModal
          open={openPostArtifaiModal}
          handleClose={() => setOpenPostArtifaiModal(false)}
          titletext={
            <DialogTitle id="form-dialog-title">
              Great Work!{" "}
              <span role="img" aria-label="Rocket Ship">
                🚀
              </span>
            </DialogTitle>
          }
          contenttext={
            <DialogContentText>
              You just created an artifaication! It will be ready in 4-6
              minutes; Sign in to save your creations and get email
              notifications when they finish processing!
            </DialogContentText>
          }
        />
        <ShareModal />
        <GalleryModal open={showGallery} src={srcGallery} />
        <Drawer
          variant="temporary"
          /* anchor={theme.direction === 'rtl' ? 'right' : 'left'} */
          open={drawerOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            className={classes.closeMenuButton}
          >
            <CloseIcon />
          </IconButton>
          {drawer}
        </Drawer>
      </div>

      <Switch>
        <Route exact path="/" component={IndexViewController} />
        <Route
          path="/upload"
          render={(props) => (
            <Upload
              {...props}
              activatePostArtifaiModal={() => setOpenPostArtifaiModal(true)}
            />
          )}
        />
        <Route path="/explore" component={Explore} />
        <Route
          path="/artifaication/:artifaicationId"
          component={Artifaication}
        />
        <Route path="/product/:artifaicationId" component={Product} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/orders" component={Orders} />
      </Switch>
      <LoadingSnackBar />
    </div>
  );
}
