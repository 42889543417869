import React, { useEffect } from "react";
import TripleImageViewList from "./components/TripleImageViewList";
import Typography from "@material-ui/core/Typography";
import { useFirestore } from "react-redux-firebase";

export default () => {
  // const recentArtifaications = useSelector(
  //   (state) => state.firestore.ordered.recentArtifaications || []
  // );

  const firestore = useFirestore();
  const [recentArtifaications, setRecentArtifaications] = React.useState([]);

  const loadMorePosts = () => {
    (async () => {
      let recentArtifaicationsQuery = firestore
        .collectionGroup("artifaications")
        .where("accessLevel", "==", "public")
        .where("percentProcessed", "==", 100)
        .orderBy("created", "desc");

      recentArtifaications.length !== 0 &&
        (recentArtifaicationsQuery = recentArtifaicationsQuery.startAfter(
          recentArtifaications.slice(-1)[0]["created"]
        ));

      recentArtifaicationsQuery = recentArtifaicationsQuery.limit(15);

      let recentArtifaicationsSnapshot = [];
      const querySnapshot = await recentArtifaicationsQuery.get();

      querySnapshot.forEach((doc) => {
        recentArtifaicationsSnapshot.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      setRecentArtifaications([
        ...recentArtifaications,
        ...recentArtifaicationsSnapshot,
      ]);
    })();
  };

  // loadMorePosts();
  useEffect(() => {
    loadMorePosts();
  }, []);

  // useFirestoreConnect([
  //   {
  //     type: "once",
  //     collectionGroup: `artifaications`,
  //     orderBy: ["created", "desc"],
  //     where: [
  //       ["accessLevel", "==", "public"],
  //       ["percentProcessed", "==", 100],
  //     ],
  //     limit: 15,
  //     queryParams: [`startAt=${startIndex}`],
  //     storeAs: "recentArtifaications",
  //     // queryParams: ["orderByChild=created", "limitToLast=10"],
  //   },
  // ]);

  return (
    <>
      <Typography variant="h2" style={{ marginTop: "150px", fontSize: "3rem" }} align="center">
        Explore Other's Creations
      </Typography>
      <TripleImageViewList
        feed={recentArtifaications}
        view="general"
        loadMorePosts={loadMorePosts}
      />
    </>
  );
};
